import React, { useState, useMemo, useEffect, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import ProfilePic from '../components/ProfilePic';
import { useAPIContext } from '../contexts/API';

const Lnk = styled(Link)`
  color: initial;
  text-decoration: initial;
`;
const Title = styled(LinearLayout)`
  flex-direction: row;
  font-size: 2em;
  justify-content: space-between;
  & > * {
    padding: 10px;
  }
`;
const SVG = styled.svg`
  width: 90%;
  margin-bottom: 5px;
  & * {
    transition: 1s;
  }
`;
const Bar = styled.rect`
  &.shake {
    transform-origin: center;
    animation: tilt-shaking infinite 0.7s linear;
  }
`;

const groups = [
  "A",
  "G",
  "H",
  "R",
  "S",
], n = groups.length, colors = [
  "#696668",
  "#b24a45",
  "#f1c036",
  "#37627e",
  "#016662",
];
export default function Map({}){
  const { user, request } = useAPIContext();
  const [_scores, setScores] = useState(false);
  const scores = _scores === false ? false : _scores.filter(e => groups.includes(e.group));
  const [idx, setIdx] = useState(-1);
  const shake = Boolean(idx.length && idx[0] !== idx[1]);
  const pts = scores !== false && scores.filter(
    (e, i) => e.lock || i <= (idx.length ? idx[0] : idx)
  ).reduce((a, c) => ({ ...a, [c.group]: a[c.group] + c.score }), Object.fromEntries(groups.map(e => [e, 0])));
  const rk = groups.map(e => [pts[e], e]).sort((a, b) => a[0] === b[0] ? a[1] - b[1] : b[0] - a[0]).reduce((a, [s, g], i) => ({ ...a, [g]: i }), {});

  const next = useCallback(() => {
    if(scores) setIdx(idx => idx.length ? idx[1] : [idx, scores.map((e, i) => [e, i]).filter(([e, i]) => !e.lock && i > idx)[0]?.[1] ?? idx]);
  }, [scores]);
  const prev = useCallback(() => {
    if(scores) setIdx(idx => idx.length ? idx[1] : [idx, scores.map((e, i) => [e, i]).reverse().filter(([e, i]) => !e.lock && i < idx)[0]?.[1] ?? -1]);
  }, [scores]);

  useEffect(() => {
    function f(e){
      // console.log(e.key);
      if(e.key === "ArrowRight") next();
      if(e.key === "ArrowLeft") prev();
    }
    document.addEventListener('keydown', f);
    return () => document.removeEventListener('keydown', f);
  }, [next, prev]);

  useEffect(() => {
    if(idx.length){
      const id = setTimeout(() => {
        next();
      }, 2000);
      return () => {
        clearTimeout(id);
      }
    }
  }, [idx]);

  useEffect(() => {
    (async () => {
      let res = await request('/memo/scores');
      if(res.ok){
        setScores(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);

  function width(pt){
    const mx = Object.values(pts).reduce((a, b) => Math.max(a, b));
    return mx === 0 ? 0 : pt / mx;
  }

  return (scores !== false) && (
    <LinearLayout style={{ paddingTop: '130px' }}>
      <Title>
        <span onClick={prev}>⬅️</span>
        {scores[idx.length ? idx[1] : idx]?.title || ""}
        <span onClick={next}>➡️</span>
      </Title>
      <SVG viewBox="0 0 400 250">
        {/*<rect width="400" height="400" style={{ fill: 'lightgray' }} />*/}
        {groups.map((g, i) =>
          <Fragment key={g}>
            <Bar
              width={`${92 * width(pts[g])}%`}
              height={`${100 / n * 5/6}%`}
              x="8%" y={`${100 / n * (rk[g]+1/12)}%`}
              className={shake ? 'shake' : ''}
              style={{ animationDelay: `${-Math.random()}s` }}
              fill={colors[i]}
            />)
            <text x="0" y="0" textAnchor="end" dominantBaseline="middle" fill="black" style={{
              transform: `translate(5%, ${100 / n * (rk[g]+0.5)}%)`
            }}>{g}</text>
            <text x="0" y="0" textAnchor="end" dominantBaseline="middle" fill="white" style={{
              transform: `translate(${8 + 92 * width(pts[g])}%, ${100 / n * (rk[g]+0.5)}%)`,
            }}>{pts[g] === 0 ? "" : pts[g]}&nbsp;&nbsp;</text>
          </Fragment>)}
      </SVG>
      {/*{groups.map(g =>
        <span key={g}>{g} {pts[g]}</span>)}*/}
    </LinearLayout>
  );
}