import { useLocation } from "react-router-dom";
import React, { useState, useMemo, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import logo_src from '../assests/logo.png';
import map_bg_src from '../assests/map-bg.png';
import { useAPIContext } from '../contexts/API';

const Lnk = styled(Link)`
  color: initial;
  text-decoration: initial;
  &::before {
    content: "🪄";
    padding-right: 5px;
  }
`;

const NavList = styled(LinearLayout)`
  width: fit-content;
  padding: 5px 0;
  align-items: flex-start;
`;

const LinkItem = styled.div`
  width: 100px;
  height: 24px;
  transition: 0.2s;
  overflow: hidden;
  margin: 2px;
  &.hide {
    margin: 0;
    width: 0;
    height: 0;
  }
`;

const _routes = [
  {
    name: "個人地圖",
    path: "/",
  },
  {
    name: "個人資訊",
    path: "/info",
  },
  {
    name: "分類帽",
    path: "/sorting-hat",
  },
  {
    name: "營隊成員",
    path: "/member",
  },
  {
    name: "留言板",
    path: "/comment",
  },
  {
    name: "登出",
    path: "/sign-out",
  },
], _parents_routes = [
  {
    name: "個人地圖",
    path: "/",
  },
  {
    name: "登出",
    path: "/sign-out",
  },
], admin_routes = [
  {
    name: "Admin",
    path: "/admin"
  },
  {
    name: "計分板",
    path: "/scoreboard"
  }
];

export default function Navigation({ parent=false }){
  const { user } = useAPIContext();
  const [expand, setExpand] = useState(false);
  const path = '/' + useLocation().pathname.split('/')[1];
  const routes = [].concat(
    (parent ? _parents_routes : _routes),
    user.admin === true ? admin_routes : [],
  );
  const match = routes.filter(e => path.startsWith(e.path)).map(e => [e.path.length, e]).reduce((a, b) => a > b ? a : b, [-1, { name: "" }]);
  const title = match[0] === 1 && path !== '/' ? false : match[1].name;

  return (
    <div style={{
      maxWidth: '100vh',
      minHeight: '100vh',
      margin: 'auto',
      position: 'relative',
      background: '#dec48d',
    }}>
      <LinearLayout style={{ left: 0, top: 0, position: "absolute", zIndex: 1, width: 'fit-content', alignItems: 'flex-start' }}>
        <img style={{ margin: '5px', height: '60px' }} src={logo_src} />
        {title && <h2 style={{ margin: '10px' }}>🪄{title}</h2>}
      </LinearLayout>
      <div style={{ right: 0, top: 0, position: "absolute", zIndex: 1 }}>
        <div style={{
          backgroundColor: '#9a8d73',
          margin: '15px',
          padding: '0px 10px',
          borderRadius: '10px',
          userSelect: 'none',
        }}>
          <div style={{ display: 'flex' }}>
            <NavList>
              {routes.map(({ name, path }) =>
                <LinkItem className={!expand ? "hide" : ""} key={name}>
                  <Lnk onClick={e => setExpand(x => !x)} to={path}>{name}</Lnk>
                </LinkItem>)}
            </NavList>
            <span style={{ fontSize: 'x-large' }} onClick={e => setExpand(x => !x)}>&equiv;</span>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}