export const checkpoints = [
  { name: "day1-arrival", description: "Day 1\n抵達德田館", x: 62, y: 38 },
  { name: "day1-activity1", description: "Day 1\n活動一", x: 155, y: 48 },
  { name: "day1-activity2", description: "Day 1\n活動二", x: 245, y: 59 },
  { name: "day1-activity3", description: "Day 1\n活動三", x: 241, y: 120 },
  { name: "day1-hotel", description: "Day 1\n抵達飯店", x: 140, y: 142 },
  { name: "day2-arrival", description: "Day 2\n抵達德田館", x: 42, y: 180 },
  { name: "day2-activity1", description: "Day 2\n活動一", x: 131, y: 216 },
  { name: "day2-activity2", description: "Day 2\n活動二", x: 232, y: 236 },
  { name: "day2-activity3", description: "Day 2\n活動三", x: 271, y: 295 },
  { name: "day2-hotel", description: "Day 2\n抵達飯店", x: 163, y: 309 },
  { name: "day3-arrival", description: "Day 3\n抵達德田館", x: 47, y: 350 },
  { name: "day3-activity1", description: "Day 3\n活動一", x: 138, y: 396 },
  { name: "day3-activity2", description: "Day 3\n活動二", x: 248, y: 426 },
  { name: "day3-activity3", description: "Day 3\n活動三", x: 228, y: 484 },
  { name: "day3-hotel", description: "Day 3\n抵達飯店", x: 122, y: 496 },
  { name: "day4-arrival", description: "Day 4\n抵達德田館", x: 56, y: 542 },
  { name: "day4-activity1", description: "Day 4\n活動一", x: 150, y: 584 },
  { name: "day4-activity2", description: "Day 4\n活動二", x: 254, y: 606 },
  { name: "day4-activity3", description: "Day 4\n活動三", x: 260, y: 676 },
  { name: "day4-hotel", description: "Day 4\n抵達飯店", x: 146, y: 688 },
  { name: "day5-arrival", description: "Day 5\n抵達德田館", x: 46, y: 724 },
  { name: "day5-activity1", description: "Day 5\n活動一", x: 153, y: 765 },
  { name: "day5-activity2", description: "Day 5\n活動二", x: 260, y: 789 },
  { name: "day5-activity3", description: "Day 5\n活動三", x: 258, y: 838 },
  { name: "day5-hotel", description: "Day 5\n抵達飯店", x: 143, y: 860 },
  { name: "day6-arrival", description: "Day 6\n抵達德田館", x: 50, y: 896 },
  { name: "day6-closing", description: "Day 6\n結業式", x: 176, y: 946 },
  { name: "day6-return", description: "Day 6\n離開臺大校園", x: 300, y: 953 },
].map(e => ({ ...e, x: e.x / 351, y: e.y / 1000 }));