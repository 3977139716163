import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Button from '../components/Button';
import ProfilePic from '../components/ProfilePic';
import { useAPIContext } from '../contexts/API';

function List({ get, set, data }){
  return (
    <table><tbody>
      {data.map(({ title, value, edit, type="text" }, i) =>
        <tr key={i}>
          <td style={{ textAlign: 'right' }}>{title}：</td>
          <td style={{ maxWidth: '45vw', overflow: 'auto' }}>{edit ?
            <input
              onChange={e => set(x => ({ ...x, [edit]: e.target.value }))}
              value={get[edit] === undefined ? value : get[edit]}
              style={{ maxWidth: '-webkit-fill-available' }}
              type={type}
            />
          : value || get[edit]}</td>
        </tr>)}
    </tbody></table>
  );
}

export default function Info({}){
  const [searchParams,] = useSearchParams();
  const { user, request } = useAPIContext();
  const [info, setInfo] = useState(false);
  const [edit, setEdit] = useState({});
  const { new_password, current_password, ..._edit } = edit;
  const changed = Boolean(info && (Object.entries(_edit).some(([k, v]) => v !== info[k]) || (new_password && current_password)));
  const [pic, setPic] = useState(0);

  const target = searchParams.get("name") || "";

  useEffect(() => {
    (async () => {
      let res = await request(`/user/${target}`);
      if(res.ok){
        res = await res.json()
        setInfo(res);
        setEdit({});
      }else{
        window.confirm("error");
      }
    })();
  }, [request, target]);

  async function save_change(){
    if(Object.entries(_edit).length){
      let res = await request(`/user`, {
        method: "POST",
        body: JSON.stringify(_edit)
      });
      if(res.ok){
        res = await res.json()
        setInfo(res);
      }else{
        return window.confirm("error");
      }
    }
    if(new_password && current_password){
      let res = await request(`/reset-password`, {
        method: "POST",
        body: JSON.stringify({ new_password, current_password })
      });
      if(res.ok){
      }else{
        return window.confirm("error");
      }
    }
    setEdit({});
  }

  async function delete_pic(){
    await request(`/head`, {
      method: "DELETE"
    });
    setPic(new Date().getTime());
  }
  console.log(edit, new_password);
  const data = info && (target === "" ? [
    { title: "小隊", value: info.role.split('/')[1] },
    { title: "學院", value: info.role.split('/')[0] },
    { title: "FB", value: info.fb, edit: "fb" },
    { title: "IG", value: info.ig, edit: "ig" },
    { title: "新密碼", value: "", edit: "new_password", type: "password" },
    ...(new_password ? [{ title: "原始密碼", value: "", edit: "current_password", type: "password" }] : [])
  ] : [
    { title: "小隊", value: info.role.split('/')[1] },
    { title: "學院", value: info.role.split('/')[0] },
    { title: "FB", value: <a style={{ display: 'block' }} href={
      (info.fb || "").startsWith('http') ? info.fb : `https://www.facebook.com/public/${info.fb}`
    }><nobr>{info.fb}</nobr></a> },
    { title: "IG", value: <a style={{ display: 'block' }} href={`https://www.instagram.com/${info.ig}`}><nobr>{info.ig}</nobr></a> },
  ]);

  return (info !== false) && (
    <LinearLayout style={{ flexDirection: 'row', paddingTop: '130px' }}>
      <LinearLayout style={{ width: 'fit-content', marginRight: '16px' }}>
        <ProfilePic src={`${process.env.REACT_APP_API_URL}/head#${pic}`} sz={100} />
        <h2 style={{ margin: '5px 0' }}>{info.name}</h2>
      </LinearLayout>
      <LinearLayout style={{ width: 'fit-content' }}>
        {/*<iframe name="dummyframe" id="dummyframe" style={{ display: 'none' }}></iframe>
        <form
          method="POST"
          action={process.env.REACT_APP_API_URL + '/upload'}
          encType="multipart/form-data"
          target="dummyframe"
        >
          <input type="file" name="myfile" accept="image/*"></input>
          <input type="submit"/>
        </form>
        <button onClick={delete_pic}>delete head</button>*/}

        <List data={data} get={edit} set={setEdit} />
        {changed && <Button onClick={save_change} style={{ marginTop: '20px' }}>儲存變更</Button>}
      </LinearLayout>
    </LinearLayout>
  );
}