import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import useScrollPercentage from "../contexts/useScrollPercentage";

import LinearLayout from '../components/LinearLayout';
import map_road_src from '../assests/map-road.png';
import map_bg_src from '../assests/map-bg.png';
import checked_src from '../assests/checked.svg';
import { useAPIContext } from '../contexts/API';
import { checkpoints } from '../config';

const Point = styled(LinearLayout)`
  background: #9a8d73;
  // border-radius: 10px;
  padding: 0px 10px;
  font-size: small;
`;

export default function Map({ write=true }){
  const [ref, scrollP] = useScrollPercentage();
  const { user, request } = useAPIContext();
  const [pts, setPts] = useState(false);
  const checked = pts && new Set(pts);
  const [h, setH] = useState("100%");

  useEffect(() => {
    (async () => {
      let res = await request('/checkpoints');
      if(res.ok){
        setPts(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);

  async function check(name){
    if(checked.has(name)) return;
    let res = await request('/check', {
      method: "POST",
      body: JSON.stringify({
        checkpoint: name
      })
    });
    if(res.ok){
      setPts(await res.json());
    }else{
      if(res.status == 400)
        window.confirm("尚未開放點名");
      else
        window.confirm("error");
    }
  }

  function onLoad(e){
    setH(`${e.target.scrollHeight}px`);
  }

  return (pts !== false) && (
    <div style={{ height: '100vh', overflow: 'auto' }} ref={ref}>
      <div style={{ position: 'relative', paddingTop: '120px', overflow: 'hidden' }}>
        <img src={map_bg_src} onLoad={onLoad} style={{ position: 'absolute', width: '100%', left: '0',
        top: `calc(${100 * scrollP}% - ${scrollP} * ${h}`, transform: 'translate3d(0,0,0)' }}/>
        <div style={{ position: 'relative' }}>
          {h !== "100%" && checkpoints.map(({ name, description, x, y }) =>
            <div key={name} style={{ left: `${100 * x}%`, top: `${100 * y}%`, position: 'absolute', transform: 'translate(-50%, -50%)' }}>
              <div style={{ position: 'relative' }}>
                <Point onClick={() => write && check(name)} style={{
                  width: 'max-content', opacity: checked.has(name) ? '60%' : undefined
                }}>
                  {description.split('\n').map((e, i) => <span key={i}>{e}</span>)}
                </Point>
                {checked.has(name) && <img src={checked_src} style={{
                  position: 'absolute', height: '70%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                }} />}
              </div>
            </div>)}
          <img src={map_road_src} style={{ width: '100%' }}/>
        </div>
      </div>
    </div>
  );
    // <LinearLayout style={{ backgroundImage: `url(${map_road_src})`, backgroundSize: 'cover' }}>
    //   {checkpoints.map(({ name, description }) =>
    //     <div key={name}>
    //       <div>{name} <span onClick={() => check(name)}>{checked.has(name) ? "✅" : "❌"}</span> {description}</div>
    //     </div>)}
    // </LinearLayout>
}