import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import hat_src from '../assests/hat.mp4';
import { useAPIContext } from '../contexts/API';

function interp(a, p0, p1){
  a = (a.length < 2 ? [' ', ...a, ' '] : a);
  return a.map((e, i) => ({
    ...p0, w: e,
    s: (p0.s * (a.length-1-i) + p1.s * i) / (a.length-1),
    x: (p0.x * (a.length-1-i) + p1.x * i) / (a.length-1),
    y: (p0.y * (a.length-1-i) + p1.y * i) / (a.length-1),
  }));
}

export default function Info({}){
  const { user, request } = useAPIContext();
  const [info, setInfo] = useState(false);
  const [time, setTime] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    (async () => {
      let res = await request(`/user`);
      if(res.ok){
        setInfo(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);


  function grp(){
    if(!info.group) return info.role;
    return info.group[info.group.length - 1];
  }

  useEffect(() => {
    let id = null;
    const frame = () => {
      if(ref.current) setTime(ref.current.currentTime);
      id = requestAnimationFrame(frame);
    }
    id = requestAnimationFrame(frame);
    return function(){ cancelAnimationFrame(id); }
  }, [])

  return (info !== false) && (
    <LinearLayout style={{ paddingTop: '130px' }}>
      <div style={{ position: 'relative', width: '90%' }}>
        <video ref={ref} src={hat_src} style={{ width: '100%' }} autoPlay loop onClick={e => e.target.play()}/>
        {[
          ...interp(info.name.split(''),
            { s: 4.0, d: 2.3, x:  7.895, y: 48.247, r: -12 },
            { s: 4.3, d: 2.3, x: 28.070, y: 43.860, r: -12 },
          ),

          ...interp("你將前往———".split(''),
            { s: 4.7, d: 2.05, x: 20, y: 90 },
            { s: 5.6, d: 2.05, x: 80, y: 90 },
          ),

          ...interp(((grp() || "霍格華爾資")+ "！").split(''),
            { s: 8.2, d: 2, x: 20, y: 90 },
            { s: 8.8, d: 2, x: 80, y: 90 },
          ),
        ].map(({ w, x, y, s, d, r=0 }, i) => (
          s <= time && time <= s + d &&
            <div key={i} style={{ left: `${x}%`, top: `${y}%`, transform: 'translate(-50%, -50%)', position: 'absolute' }}>
              <div style={{ transform: `rotate(${r}deg)`, fontSize: '5vw' }}>{w}</div>
            </div>
        ))}
      </div>
      {/*<h3>Now</h3>
      <span>{info.name}@{grp()}</span>
      <h5>Past groups</h5>
      {(info.group || []).map((e, i) =>
        <span key={i}>{e}</span>)}*/}
    </LinearLayout>
  );
}