import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import ProfilePic from '../components/ProfilePic';
import { useAPIContext } from '../contexts/API';

const Lnk = styled(Link)`
  color: initial;
  text-decoration: initial;
`;

export default function Map({}){
  const { user, request } = useAPIContext();
  const [member, setMember] = useState(false);
  const checked = member && new Set(member);

  useEffect(() => {
    (async () => {
      let res = await request('/members');
      if(res.ok){
        setMember(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);

  return (member !== false) && (
    <LinearLayout style={{ flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: '130px', paddingBottom: '20px' }}>
      {member.map(({ name, role }) =>
        <Lnk to={`/info?name=${name}`} key={name} style={{ width: "25%", minWidth: "120px", marginBottom: '20px' }}>
          <LinearLayout>
            <ProfilePic src={`${process.env.REACT_APP_API_URL}/head/${name}`} sz={60} loading="lazy" />
            <div style={{ textAlign: 'center' }}>{role.split('/')[1]}</div>
            <div>{name}</div>
          </LinearLayout>
        </Lnk>)}
    </LinearLayout>
  );
}