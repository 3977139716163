import React, { useEffect, createContext, useContext, useMemo, useCallback, useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { CookiesProvider, useCookies } from 'react-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const APIContext = createContext(false);
export const useAPIContext = () => useContext(APIContext);

export function API({ children }){
  const [cookies, scookie, rmcookie] = useCookies();
  const token = useMemo(() => cookies?.csiecamp || null, [cookies]);
  const [user] = useMemo(() => {
    try{
      return [token && jwt_decode(token)];
    }catch(e){
      console.error(e);
      // if(e.name === 'InvalidTokenError') 
      return [null];
    }
  }, [token]);
  // useEffect(() => console.log(user), [user]);

  const request = async (a, b={}, ...c) => {
    try{
      const res = await fetch(API_URL + a, {
        ...b, headers: {
          ...(b.headers || {}),
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }, ...c);
      if(res.status == 401){
        rmcookie('csiecamp');
        return { ok: false };
      }
      return res;
    }catch{
      return { ok: false };
    }
  }
  async function fakeReq(x=true, ...r){
    await new Promise(f => setTimeout(f, 0.5 * 1e3));
    return {
      status: 200,
      ok: true,
      json: () => x,
    };
  }
  function signout(){
    rmcookie('csiecamp');
  }

  return (
    <CookiesProvider>
      <APIContext.Provider value={{
        user, request, fakeReq, signout
      }}>
        {children}
      </APIContext.Provider>
    </CookiesProvider>
  );
}