import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';

export default function Comment({}){
  const { user, request } = useAPIContext();
  const [anonym, setAnonym] = useState("");
  const [news, setNews] = useState("");
  const [fetching, setFetching] = useState(false);
  const [cmts, setCmts] = useState([]);

  async function fetchComment(){
    let res = await request('/comment');
    if(res.ok){
      setCmts(await res.json());
    }else{

    }
  };

  useEffect(() => {
    fetchComment();
  }, []);

  async function submit(type){
    const [val, set] = { anonymous: [anonym, setAnonym], news: [news, setNews] }[type];
    if(val.length === 0 || fetching) return;
    setFetching(true);
    console.log(val);
    try{
      let res = await request('/comment', {
        method: 'POST',
        body: JSON.stringify({ type, content: val })
      });
      if(!res.ok){
        window.confirm("submission failed");
      }
      await fetchComment();
    }finally{
      setFetching(false);
    }
    set("");
  }

  return (
    <LinearLayout style={{ paddingTop: '130px' }}>
      <LinearLayout style={{ alignItems: 'flex-start', width: 'fit-content', maxWidth: '95%' }}>
        <h4 style={{ marginBottom: '8px', marginTop: 0 }}>匿名悄悄話：有什麼想跟召部、隊輔反映的嗎？</h4>
        <textarea value={anonym} onChange={e => setAnonym(e.target.value)} cols="40" rows="7" style={{ width: '330px', maxWidth: '-webkit-fill-available', resize: 'none' }}/>
        <Button onClick={() => submit("anonymous")} style={{ margin: 'auto', marginTop: '8px' }}>上傳</Button>

        <h4 style={{ marginBottom: '8px' }}>新聞投稿區：有什麼營隊趣事，八卦爆料呢？</h4>
        <textarea value={news} onChange={e => setNews(e.target.value)} cols="40" rows="7" style={{ width: '330px', maxWidth: '-webkit-fill-available', resize: 'none' }}/>
        <Button onClick={() => submit("news")} style={{ margin: 'auto', marginTop: '8px' }}>上傳</Button>
      </LinearLayout>
    </LinearLayout>
  );
}