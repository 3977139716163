import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';
import logo_src from '../assests/logo.png';
import dancers_src from '../assests/dancers.png';

export default function Login({}){
  const { request } = useAPIContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function login(){
    let res = await request('/authenticate', {
      method: 'POST',
      body: JSON.stringify({
        username, password
      })
    });
    if(res.ok){
      console.log(res);
      window.location = '/';
    }else{
      window.confirm("login failed");
    }
  }

  return (
    <LinearLayout style={{ minHeight: '100vh', background: '#dec48d', margin: 'auto', position: 'relative', maxWidth: '100vh' }}>
      <LinearLayout style={{ maxHeight: '600px' }}>
        <img style={{ margin: '5px', width: '90%' }} src={logo_src} />
        <LinearLayout style={{ margin: '30px 0', width: 'fit-content', alignItems: 'flex-start' }}>
          <label style={{ marginTop: '10px' }}>姓名</label>
          <input value={username} onChange={e => setUsername(e.target.value)} type="text" />
          <label style={{ marginTop: '10px' }}>密碼</label>
          <input value={password} onChange={e => setPassword(e.target.value)} type="password" />
          <Button onClick={login} style={{
            margin: 'auto',
            marginTop: '20px',
          }}>登入</Button>
        </LinearLayout>
        <img style={{ margin: '5px', width: '80%' }} src={dancers_src} />
      </LinearLayout>
    </LinearLayout>
  );
}