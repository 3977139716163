import { useEffect } from "react";

import { useAPIContext } from '../contexts/API';

export default function Login({}){
  const { signout } = useAPIContext();

  useEffect(() => {
    (async () => {
      signout();
      // window.location = '/';
    })();
  }, []);

  return "";
}