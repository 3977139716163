import { useEffect, useState } from "react";

import { useAPIContext } from '../contexts/API';
import LinearLayout from '../components/LinearLayout';
import Button from '../components/Button';

export default function Install({}){
  const [e, setE] = useState(null);

  function install(){
    e.prompt();
    e.userChoice.then(choice => {
      if(choice.outcome === 'accepted'){
          console.log('user accepted the prompt')
      }
      window.location = '/';
    })
  }

  useEffect(() => {
    function f(event){
      event.preventDefault();
      setE(event);
    }
    window.addEventListener('beforeinstallprompt', f);
    return () => window.removeEventListener('beforeinstallprompt', f);
  }, []);

  return (
    <LinearLayout style={{ height: '100vh', background: '#dec48d', margin: 'auto', position: 'relative', maxWidth: '100vh' }}>
      <Button onClick={install}>下載</Button>
    </LinearLayout>
  );
}