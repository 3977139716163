import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";

import './App.css';
import Login from './screens/Login';
import Map from './screens/Map';
import Info from './screens/Info';
import SortingHat from './screens/SortingHat';
import Member from './screens/Member';
import Comment from './screens/Comment';
import Navigation from './screens/Navigation';
import SignOut from './screens/SignOut';
import Install from './screens/Install';
import Admin from './screens/Admin';
import Scoreboard from './screens/Scoreboard';
import { API, useAPIContext } from './contexts/API';

function Router() {
  const { user } = useAPIContext();
  if(!user) return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/install" element={<Install />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
  console.log(user);
  if(user.role.split('/')[0] === "家長"){
    return (
      <Routes>
        <Route path="/" element={<Navigation parent />}>
          <Route path="/" element={<Map write={false} />} />
          <Route path="/sign-out" element={<SignOut />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    );
  }else{
    return (
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route path="/" element={<Map />} />
          <Route path="/info" element={<Info />} />
          <Route path="/sorting-hat" element={<SortingHat />} />
          <Route path="/member" element={<Member />} />
          <Route path="/comment" element={<Comment />} />
          <Route path="/sign-out" element={<SignOut />} />
          <Route path="/install" element={<Install />} />
          {user.admin && <Route path="/admin" element={<Admin />} />}
          {user.admin && <Route path="/scoreboard" element={<Scoreboard />} />}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    );
  }
}

function App() {
  return (
    <API>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </API>
  );
}

export default App;
