import { useRef, useState, useEffect } from "react";

export default function useScrollPercentage() {
  const [scrollPercentage, setScrollPercentage] = useState(NaN);
  const ref = useRef(null);
  const reqRef = useRef(null);

  const reportScroll = () => {
    setScrollPercentage(getScrollPercentage(ref.current));
  };

  useEffect(() => {
    let id = null;
    const frame = () => {
      if(ref.current) reportScroll();
      id = requestAnimationFrame(frame);
    }
    id = requestAnimationFrame(frame);
    return function(){ cancelAnimationFrame(id); }
  }, [])

  return [ref, Number.isNaN(scrollPercentage) ? 0 : scrollPercentage];
}

function getScrollPercentage(element) {
  if (element === null) {
    return NaN;
  }
  const height = element.scrollHeight - element.clientHeight;
  return (element.scrollTop / height);
}
