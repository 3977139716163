import React from "react";

import frame_src from '../assests/frame.png';

const s = 1600
const x = [195, 1125, 280];
const y = [357, 1145, 98];
function p(x){ return `${x * 100}%`; }

export default function ProfilePic({ src, sz, ...props }){
  return (
    <div style={{
      position: 'relative', width: `${sz}px`, height: `${sz}px`,
      margin: `${sz * 30 / 100}px ${sz * 25 / 100}px ${sz * 6 / 100}px ${sz * 25 / 100}px` }}>
      <img width={sz} height={sz} src={src} style={{ borderRadius: "50%" }}/>
      <img src={frame_src} style={{
        position: 'absolute',
        width: p(s/x[1]), height: p(s/y[1]),
        left: p(-x[0]/x[1]), top: p(-y[0]/y[1])
      }} {...props}/>
    </div>
  );
}
