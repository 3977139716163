import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';
import { checkpoints } from '../config';

const PanelContainer = styled(LinearLayout)`
  align-items: flex-start;
  width: 90%;
  background: #9c926b;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  h3 {
    margin: 5px 0;
    width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  td {
    text-align: center;
  }
`;

const Btn = styled.td`
  border: 1px solid black;
`;

function Panel({ name, children }){
  const [show, setShow] = useState(false);
  return (
    <PanelContainer>
      <h3 onClick={() => setShow(e => !e)}>{show ? "Ｖ" : "＞"} {name}</h3>
      <div style={{ display: show ? 'block' : 'none', width: '100%' }}>{children}</div>
    </PanelContainer>
  );
}

const scoreAdmins = ["王勻", "陳德維"];

export default function Admin({}){
  const { user, request } = useAPIContext();
  const [checkMode, setCheckMode] = useState(null);
  const [cd, setCD] = useState(0);

  useEffect(() => {
    const id = setInterval(() => setCD(e => Math.max(0, e-1)), 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    (async () => {
      let res = await request('/check/mode');
      if(res.ok){
        res = await res.json();
        console.log(res);
        setCheckMode(res.mode);
        setCD(Math.max(0, parseInt((new Date(res.time).getTime() - new Date().getTime()) / 1000)));
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);

  async function changeCheckMode(evt){
    console.log(evt);
    let [mode, time, ..._] = Array.from(evt.target).map(e => e.value);
    if(mode === "None") mode = null;
    time = Number(time);
    let res = await request('/memo', {
      method: "POST",
      body: JSON.stringify({
        check: {
          mode, time: new Date(1000 * 60 * time + new Date().getTime()),
        }
      })
    });
    if(res.ok){
      res = (await res.json());
      setCheckMode(res.data.check.mode);
      setCD(Math.max(0, parseInt((new Date(res.data.check.time).getTime() - new Date().getTime()) / 1000)));
    }else{
      window.confirm("error");
    }
  }

  const [scores, setScores] = useState([]);
  useEffect(() => {
    (async () => {
      let res = await request('/memo/scores');
      if(res.ok){
        setScores(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);
  async function changeScores(){
    console.log(scores);
    let res = await request('/memo', {
      method: "POST",
      body: JSON.stringify({
        scores
      })
    });
    if(res.ok){
      setScores((await res.json()).data.scores);
    }else{
      window.confirm("error");
    }
  }
  function add_scores(str){
    try{
      setScores(s => s.concat(str.trim().split('\n').map(e => e.trim().split(/\s+/)).map(
        ([title, group, score]) => ({ title, group, score: parseInt(score) })
      )));
    }finally{

    }
  }

  const [comments, setComments] = useState([]);
  const [opts, setOpts] = useState({ anonymous: true });
  useEffect(() => {
    (async () => {
      let res = await request('/comment');
      if(res.ok){
        setComments(await res.json());
      }else{
        window.confirm("error");
      }
    })();
  }, [request]);

  return (
    <LinearLayout style={{ paddingTop: '130px', fontFamily: 'consolas' }}>
      <iframe name="dummyframe" id="dummyframe" style={{ display: 'none' }}></iframe>
      <Panel name="點名狀態">
        <form onSubmit={changeCheckMode} target="dummyframe">
          <LinearLayout>
            {checkMode !== null && cd !== 0 &&
              <>
                <h1>點名選項：{checkpoints.filter(e => e.name === checkMode)[0].description}</h1>
                <h2>剩餘時間：{cd}秒</h2>
              </>}
            <p>
              點名選項：
              <select defaultValue={checkMode || "None"}>
                <option value="None">None</option>
                {checkpoints.map(({ name, description }) =>
                  <option key={name} value={name}>{name}</option>)}
              </select>
              <br />
              <span>點名時間：<input type="number" defaultValue="10"/> mins</span>
            </p>
            <input type="submit" value="開始" />
          </LinearLayout>
        </form>
      </Panel>
      {scoreAdmins.includes(user.name) &&
        <Panel name="分數清單">
          <LinearLayout style={{ gap: '10px' }}>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>
                    {scores.every(e => e.lock)
                      ? <button onClick={() => setScores(s => s.map(e => ({ ...e, lock: false })))}>unlock all</button>
                      : <button onClick={() => setScores(s => s.map(e => ({ ...e, lock: true })))}>lock all</button>}
                  </th>
                  <th>title</th>
                  <th>group</th>
                  <th>score</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {scores.map(({ title, group, score, lock=false }, idx) =>
                  <tr key={idx}>
                    <Btn onClick={() => setScores(s => s.map((e, i) => s[
                      i === idx-1 ? i+1 :
                      i === idx ? i-1 :
                      i
                    ] || e))}>⬆️</Btn>
                    <Btn onClick={() => setScores(s => s.map((e, i) => s[
                      i === idx ? i+1 :
                      i === idx+1 ? i-1 :
                      i
                    ] || e))}>⬇️</Btn>
                    <Btn onClick={() => setScores(s => s.map((e, i) => i === idx ? { ...e, lock: !e.lock } : e))}>{
                      lock ? <span style={{ filter: "brightness(0.5)" }}>&#128274;</span> : <span>&#128275;</span>
                    }</Btn>
                    <td>{title}</td>
                    <td>{group}</td>
                    <td>{score}</td>
                    <Btn onClick={() => setScores(s => s.filter((_, i) => i !== idx))}>❎</Btn>
                  </tr>)}
              </tbody>
            </Table>
            <form onSubmit={e => add_scores(e.target[0].value)} target="dummyframe">
              <LinearLayout style={{ gap: '10px' }}>
                <textarea cols="40" rows="5" style={{ width: '330px', resize: 'none' }} />
                <input type="submit" value="Add" />
              </LinearLayout>
            </form>
            {/*<textarea value={JSON.stringify(scores)} onChange={e => {
              try{ setScores(JSON.parse(e.target.value)) }catch(e){}
            }} cols="40" rows="3" style={{ width: '330px', resize: 'none' }}/>*/}
            <button onClick={changeScores}>Submit</button>
          </LinearLayout>
        </Panel>}
      <Panel name="留言板">
        <LinearLayout>
          <LinearLayout style={{ flexDirection: 'row', justifyContent: 'space-evenly', margin: '10px 0' }}>
            {["anonymous", "named", "past"].map(e =>
              <button key={e}
                onClick={() => setOpts(x => ({ ...x, [e]: !x[e] }))}
                style={{ opacity: opts[e] ? 1 : 0.5 }}
              >{e}</button>)}
          </LinearLayout>
          {comments.filter(
            e => opts.anonymous || e.poster !== "anonymous"
          ).filter(
            e => opts.named || e.poster === "anonymous"
          ).filter(
            e => {
              console.log(new Date().toString(),  new Date(e.createdAt).toString())
              return opts.past || new Date().toString().slice(0, 15) === new Date(e.createdAt).toString().slice(0, 15)
            }
          ).map(({ poster, content, createdAt }, i) =>
            <div key={i} style={{ borderTop: '1px black dashed', width: '100%' }}>
              <h3>{poster}<span style={{ float: 'right', fontSize: '0.83em' }}>{new Date(createdAt).toLocaleString()}</span></h3>
              <p style={{ marginTop: 0, whiteSpace: 'pre-wrap' }}>{content}</p>
            </div>)}
        </LinearLayout>
      </Panel>
    </LinearLayout>
  );
}